@import "@/styles/mixins/common.scss";

@import "@/styles/variable";

.feedBack {
    text-align: center;
    color: #fff;
    cursor: pointer;

    .text {
        font-size: 12px;
        margin-top: 4px;
    }

    :global {
        .MuiButtonBase-root {
            background-color: $theme_attach_color;
            color: #fff;

            &:hover {
                background-color: $theme_attach_color;
                color: #fff;
            }
        }
    }
}