@import "@/styles/mixins/common.scss";
@import "@/styles/variable";

$headerHeight: 70px;

.layout {
  width: 100%;
  min-height: 100vh;

  :global {
    .fixed {
      position: fixed;
      bottom: 24px;
      // debug：hover NAV栏 图标抖动
      left: calc(100vw - 65px);
      z-index: 40;
    }
  }

  .header {
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    z-index: $layout_header;
    background: #141415;
    height: $headerHeight;
  }

  .headerSticky {
    @extend .header;
    position: sticky;
  }

  .body {
    min-height: calc(100vh - 70px);
    // padding-top: $headerHeight;
    background: #141415;
    color: #fff;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
  }
}