$theme_color: #010D1F;
$theme_color_light: #1B2737;
$theme_attach_color: #36d4c7;
$theme_color_gray: #ccc;

$border_color: $theme_attach_color;
$button_color: #54b2a3;
$button_color_light: #36d4c7;
$button-color_gray: #9a9a9a;

$modal-bg-color: linear-gradient(0deg, #1B2737, #152739);
