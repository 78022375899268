@import "@/styles/mixins/common.scss";
@import "@/styles/mixins";

.fullScreenImage {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  .mainImage {
    width: 80vw;
    height: 80vh;
    @include bg-common('');
  }
  .otherImageBox {
    margin-top: 20px;
    width: 60vw;
    .otherImageWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .otherImage {
        margin-right: 20px;
        width: 10vh;
        height: 10vh;
        cursor: pointer;
        @include bg-common('');
      }
    }
  }
  .closeBox {
    position: absolute;
    right: 20px;
    top: 20px;
    .closeBtn {
      font-size: 28px;
      color: #fff;
      cursor: pointer;
    }
  }
}