@import "@/styles/mixins/common.scss";
@import "@/styles/mixins";

.uploadImgWrapper {
  display: flex;
  align-items: center;

  .uploadImgList {
    display: flex;
    align-items: center;

    .uploadImg {
      position: relative;
      width: 100px;
      height: 100px;
      margin-right: 8px;
      text-align: center;
      line-height: 100px;
      border-radius: 8px;
      @include bg-common('', cover);

      .deleteIcon {
        position: absolute;
        top: 8px;
        right: 8px;
        color: #fff;
        cursor: pointer;
        z-index: 10;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        background-color: rgba(0,0,0,0.4);
        border-radius: 8px;
      }
    }
  }

  .upload {
    width: 100px;
    height: 100px;
    border: 1px dashed #fff;
    border-radius: 8px;
    .uploadInput {
      width: 100px;
      height: 100px;
    }
  }
}