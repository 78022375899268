@import "@/styles/mixins/common.scss";
@import "@/styles/variable";


.navBar {
  position: relative;
  padding: 10px 0;
  background-color: #141415;
  color: #fff;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.5);
    z-index:-1;
  }

  .iconWrapper {
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;

    .icon {
      width: 50px;
      height: 50px;
    }

    .iconText {
      margin-left: 5px;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .linkWrapper {
    display: flex;
    align-items: center;
    .link {
      margin-left: 20px;
      color: #fff
    }
    .link:hover{
      color:$theme_attach_color;
    }
    .linkActive {
      @extend .link;
      color: $theme_attach_color;
    }

    .more {
      margin-left: 20px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    
    .more:hover{
      color:$theme_attach_color;
      &>svg{
        transform: rotate(180deg);
        transition:all 0.5s;
      }
    }
  }

  .loginWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
      color: #333;
    }
  }

  .menuBox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }
}

.avatarAndName {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.avatar {
  width: 28px;
  height: 28px;
  object-fit: cover;
  border-radius: 50%;
}

.aiPaintingTimes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  color: #36d4c7;
  font-weight: 600;
  color: #fff;
  background-color: hsla(0,0%,100%,.1);
  border-radius: 13px;
  padding: 4px 8px;
  cursor: pointer;
  height: 26px;
  transition: all 0.3s;
  max-width: 100px;
  border:1px solid transparent;

  &>img {
    margin-right: 2px;
    width: 18px;
  }
}

.personalLogin {
  display: flex;
  align-items: center;

  :global {
    .MuiSvgIcon-root {
      margin-left: 12px;
      cursor: pointer;
    }
  }

}

.new {
  background: #ec3d3d;
  color: #fff;
  transform: scale(.7);
  border-radius: 20px;
  padding: 2px 4px 4px;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 700;
}