* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: #141415;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
}

a {
    color: #36d4c7;
    text-decoration: none;
}

/* ::-webkit-scrollbar {
    display: none;
} */

::-webkit-scrollbar {
    width: 6px;
    height:10px;
    -webkit-appearance: none;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    /* background-color: rgba(0 0 0 / 0.5); */
    background-color: rgba(54, 212, 199, 0.4);
}



#root>div::-webkit-scrollbar {
    display: none;
}

