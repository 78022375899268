@import "@/styles/mixins/common.scss";
@import '@/styles/variable';

.sharePosterModal {
    :global {
        .MuiDialog-paper {
            width: 320px;
        }
    }

    img {
        width: 100%;
    }

    .downloadPoster, .copyPoster {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 24px;

        .iconWrapper {
            width: 40px;
            height: 40px;
            text-align: center;
            padding-top: 6px;
            background: $theme_attach_color;;
            border-radius: 50%;
            cursor: pointer;
            color: #fff;

            :global {
                .MuiSvgIcon-root {
                    font-size: 28px;
                }
            }
        }

        .text {
            margin-top: 4px;
        }
    }

    .copyPoster .iconWrapper {
        background-color: #7851ec;
    }
}