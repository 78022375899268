@import "@/styles/mixins/common.scss";
.touchMidScrollView {
  width: 100%;
  overflow-x: auto;
  // overscroll-behavior: contain;
  // -ms-scroll-chaining: contain;
  &.smoothMode {
    scroll-behavior: smooth;
  }
  // &::-webkit-scrollbar {
  //   display: none
  // }
}