@import "@/styles/mixins/common.scss";
.flyEmptyWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px;

    :global {
        .MuiSvgIcon-root {
            font-size: 40px;
        }
    }

    img {
        width: 101px;
    }

    .emptyContent {
        margin-top: 12px;
        color: #999;
    }
}