@import "@/styles/mixins/common.scss";
@import "@/styles/variable";

.messageNotificationInner {
    width: 440px;
    max-height: 60vh;
    overflow-y: auto;
    padding: 12px;
    background-color: #252527;

    .notificationList {
        padding: 12px;
    }
}

.messageNotificationFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background-color: #252527;

    .readAll, .checkAll {
        cursor: pointer;
    }
}

.msgList {

    .msgItem {
        width: 100%;
        background: #1e1e1f;
        border-radius: 16px;
        padding: 16px;
        color: #fcfcfd;
        
        &:not(:last-child) {
            margin-bottom: 24px;
        }

        .header {
            display: flex;
            height: 56px;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            justify-content: space-between;
            border-bottom: 2px solid rgb(53, 57, 69);

            img {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                margin-right: 8px;
                background-color: #ccc;
            }

            .createTime {
                color: #fcfcfd;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
            }
        }

        .content {
            padding: 16px 0px;
            font-weight: 400;
            line-height: 20px;
            color: #777e90;
        }

        .footer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            color: $theme_attach_color;
            cursor: pointer;

            :global {
                .MuiSvgIcon-root {
                    font-size: 14px;
                    font-weight: 800;
                }
            }
        }

    }
}