@import "@/styles/mixins/common.scss";
@import "@/styles/variable";

.link {
    color: #fff
}

.linkActive {
    @extend .link;
    color: $theme_attach_color;
}