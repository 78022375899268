@import "@/styles/mixins/common.scss";
@import '@/styles/variable';

.userCenterInner {
    min-width: 400px;
    color: #fcfcfd;
    background: #252527;
    border-radius: 4px;
    padding: 24px;

    .userName {
        font-size: 20px;
        font-weight: 700;
    }

    .memberName {
        display: flex;
        align-items: center;
        padding: 4px 8px;
        border-radius: 24px;
        color: #999;

        .name {
          margin-left: 4px;
        }

        img {
            width: 14px;
            height: 14px;
        }
    }

    .follow, .fans {
        color: #CCC;
        cursor: pointer;
    }

    :global {

        .MuiSvgIcon-root {
            cursor: pointer;
        }

        .entranceWrapper {
            margin-top: 64px;
        }

        .extraEntranceWrapper {
            margin-top: 32px;
        }
    }

    .entranceItem {
        color: #ccc;
        text-align: center;
        line-height: 20px;
        cursor: pointer;

        :global {
            .MuiSvgIcon-root {
                font-size: 40px;
                color: $theme_attach_color;;
            }
        }

        img {
            width: 40px;
            height: 40px;
        }

        .text {
            color: #ccc;
        }
    }

    .extraEntranceItem {
        display: flex;
        align-items: center;
        color: #ccc;
        cursor: pointer;

        .text {
            margin-left: 4px;
        }
    }

}
