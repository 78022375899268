@import "@/styles/mixins/common.scss";
@import "@/styles/variable";

.communication {
    text-align: center;
    color: #fff;
    cursor: pointer;

    :global {
        .MuiButtonBase-root {
            background-color: $theme_attach_color;
            color: #fff;

            &:hover {
                background-color: $theme_attach_color;
                color: #fff;
            }
        }
    }

    .iconWrapper {

        .text {
            font-size: 12px;
        }
    }
}

.communicationWay {
    text-align: center;
    margin-top: 8px;

    img {
        width: 100px;
        height: 100px;
    }

    .text {
        font-size: 12px;
        margin-top: 4px;
    }
}